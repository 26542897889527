.plans__container{
    margin-top: -4rem;
    padding: 0 2rem;
    display: flex;
    gap: 4rem;
    flex-direction: column;
    position: relative;
}
.plans{
    display: flex;
    gap: 3rem;
    justify-content: center;
    align-items: center;
}
.plans__blur-1{
    width: 32rem;
    height: 23rem;
    top: 6rem;
    left: 0rem;
}
.plans__blur-2{
    width: 32rem;
    height: 23rem;
    top: 10rem;
    right: 0rem;
}
.plan{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1.5rem;
    background: var(--caloryCard);
    width: 15rem;
    color: #fff;
}
.plan:nth-child(2){
    background: var(--planCard);
    transform: scale(1.1);
}
.plan > svg{
    width: 2rem;
    height: 2rem;
    fill: var(--orange);
}
.plan > :nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}
.plan > :nth-child(3){
    font-size: 2.5rem;
    font-weight: bold;
}
.plan > :nth-child(5){
    font-size: .8rem;
}
.plan:nth-child(6){
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.feature{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.feature img{
    width: 1rem;
}
.plans > :nth-child(2) > svg{
    fill: #fff;
}
.plans > :nth-child(2) > button{
    color: var(--orange);
}
@media screen and (max-width:768px) {
    .plans__container{
        flex-direction: column;
    }
    .plans{
        flex-direction: column;
    }
    .plan:nth-child(2){
        transform: none;
    }
}
