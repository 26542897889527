.footer__container{
    position: relative;
}
.footer__container > hr{
    border: 1px solid var(--lightgray);
}
.footer{
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding: 1rem 2rem;
    height: 20rem;
    align-items: center;
    justify-content: center;
}
.social__links{
    display: flex;
    gap: 4rem;
}
.social__links > img{
    width: 2rem;height: 2rem;
    cursor: pointer;
}
.logo__f > img{
    width: 8rem !important;
    /* margin-left: -1rem; */
}
.blur__f-1{
    bottom: 0;
    right: 15%;
    width: 26rem;height: 12rem;
    background: red;
    filter: blur(200px);
}
.blur__f-2{
    bottom: 0;
    left: 15%;
    width: 26rem;height: 12rem;
    background: rgb(255, 155, 0);
    filter: blur(200px);
}