.Join{
    display: flex;
    gap: 10rem;
    padding: 1rem 2rem;
}
.left__j{
    color: #fff;
    font-size: 3rem;
    font-weight: bold;
    position: relative;
    text-transform: uppercase;
    flex: 1 1;
}
.left__j > hr{
    position: absolute;
    left: 1rem;
    margin: -10px;
    width: 10.5rem;
    border: 2px solid var(--orange);
    border-radius: 20%;
}
.right__j{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 3rem;
    flex: 1 1;
}
.email__container{
    display: flex;
    gap: 3rem;
    background-color: gray;
    padding: 1rem 2rem;
}
.email__container > input{
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--lightgray);
}
::placeholder{
    color: var(--lightgray);
}
.btn__j{
    background-color: var(--orange);
    color: #fff;
}
@media screen and (max-width:768px){
    .Join{
        flex-direction: column;
    }
    .left__j{
        font-size: x-large;
        flex-direction: column;
    }
    .email__container{
        gap: 1rem;
        flex-direction: column;
        font-size: x-large;
    }
    .email__container > input{
        margin: 0 1rem;
    }
}