.hero{
    display: flex;
    justify-content: space-between;
}
.hero .hero__blur{
    width: 22rem;
    height: 30rem;
    left: 0;
}
.hero .left__hero{
    padding: 3rem;
    padding-top: 1.5rem;
    flex: 3;
    display: flex;
    gap: 2rem;
    flex-direction: column;
}
.hero .left__hero .the_best_ad{
    margin-top: 4rem;
    background-color: #363d42;
    border-radius: 4rem;
    text-transform: uppercase;
    width: fit-content;
    padding: 20px 13px;
    color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.hero .left__hero .the_best_ad span{
    z-index: 10;
}
.hero .left__hero .the_best_ad  div{
    position: absolute;
    background-color: var(--orange);
    left: 8px;
    width: 5.4rem;
    height: 80%;
    border-radius: 3rem;
}
.hero .left__hero .hero__text{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: #fff;
}
.hero .left__hero .hero__text > div:nth-of-type(3){
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
}
.hero .left__hero .hero__figures{
    display: flex;
    gap: 2rem;
}
.hero .left__hero .hero__figures > div{
    display: flex;
    flex-direction: column;
}
.hero .left__hero .hero__figures > div > span:nth-of-type(1){
    font-size: 2rem;
    color: #fff;
}
.hero .left__hero .hero__figures > div > span:nth-of-type(2){
    color: var(--gray);
    text-transform: none;
}
.hero .left__hero .hero__buttons{
    display: flex;
    gap: 1rem;
}
.hero .left__hero .hero__buttons > button:nth-of-type(1){
    background-color: var(--orange);
    color: #fff;
    width: 10rem;
}
.hero .left__hero .hero__buttons > button:nth-of-type(2){
    background-color: transparent;
    color: #fff;
    width: 10rem;
    border: 2px solid var(--orange);
}


.hero .right__hero{
    flex: 1;
    position: relative;
    background-color: var(--orange);
}
.hero .right__hero .btn{
    position: absolute;
    right: 2rem;
    top: 2.5rem;
}
.hero .right__hero .hero__heart_rate{
    position: absolute;
    top: 10rem;
    right: 4rem;
    background-color: var(--darkGrey);
    width: fit-content;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: start;
    padding: 1rem;
    border-radius: 5px;
}
.hero .right__hero .hero__heart_rate img{
    width: 2rem;
}

.hero .right__hero .hero__heart_rate > :nth-child(2){
    color: var(--gray);
}
.hero .right__hero .hero__heart_rate > :nth-child(3){
    color: white;
    font-size: 1.5rem;
}
.hero .right__hero .hero__image{
    position: absolute;
    top: 12rem;
    right: 8rem;
    width: 23rem;
}
.hero .right__hero .hero__image_back{
    position: absolute;
    top: 4rem;
    right: 20rem;
    width: 15rem;
    z-index: -1;
}
.hero .right__hero .hero__calories{
    display: flex;
    gap: 2rem;
    padding: 1rem;
    width: 16rem;
    background-color: var(--caloryCard);
    position: absolute;
    top: 33rem;
    right: 28rem;
    border-radius: 5px;
}
.hero .right__hero .hero__calories img{
    width: 3rem;
}
.hero .right__hero .hero__calories > div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.hero .right__hero .hero__calories > div >:nth-child(1){
    color: var(--gray);
}
.hero .right__hero .hero__calories > div >:nth-child(2){
    color: #fff;
    font-size: 1.5rem;
}

/* media queries */
@media screen and (max-width:768px) {
    .hero{
        flex-direction: column;
    }
    .hero .hero__blur{
        width: 14rem;
    }
    .hero .left__hero .the_best_ad{
        margin-top: 0;
        align-self: center;
       font-size: small;
       transform: scale(.8);
    }
    .hero .left__hero .hero__text {
        font-size: xx-large;
        align-items: center;
        justify-content: center;
    }
    .hero__text > div:nth-of-type(3){
        font-size: small;
        font-weight: 200;
        align-items: center;
    }
    .hero .left__hero .hero__buttons{
        justify-content: center;
    }
    .hero .left__hero .hero__figures{
        justify-content: center;
    }
    .hero__figures > div > span:nth-of-type(1){
        font-size: large;
    }
    .hero__figures > div > span:nth-of-type(2){
        font-size: small;
    }
    .hero .right__hero{
        position: relative;
        background: none !important;
    }
    .hero .right__hero .hero__heart_rate{
        left: 2rem;
        top: 2rem;
    }
    .hero .right__hero .hero__calories{
        position: relative;
        top: 5rem;
        left: 2rem;
        width: 10rem;
    }
    .hero .right__hero .hero__calories img{
        width: 1rem;
    }
    .hero .right__hero .hero__calories > div:nth-child(2){
        font-size: 1rem;
        color: #fff;
    }
    .hero .right__hero .hero__image{
        position: relative;
        width: 12rem;
        left: 8rem;
        top: 4rem;
        align-self: center;
    }
    .hero .right__hero .hero__image_back{
        width: 15rem;
        top: 0;
        left: 2rem;
    }

    
}
